html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
    background-color: #F2F5F6;
    height: auto;
    position: relative;
}